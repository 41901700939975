var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('EscolhaOpcaoSelect',{attrs:{"inscricao":_vm.inscricao,"opcoes":_vm.processo.opcoes}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.processo.possuiUniversidadeOrigem)?_c('v-select',{attrs:{"items":_vm.processo.listaAnoIngressoUniversidadeOrigem,"rules":[_vm.campoObrigatorioRule],"dense":"","label":"Ano de ingresso na universidade de origem *","outlined":"","validate-on-blur":""},model:{value:(_vm.inscricaoTEE.anoIngressoInstituicaoOrigem),callback:function ($$v) {_vm.$set(_vm.inscricaoTEE, "anoIngressoInstituicaoOrigem", $$v)},expression:"inscricaoTEE.anoIngressoInstituicaoOrigem"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"rules":[_vm.campoObrigatorioRule, _vm.tamanhoRule(4)],"dense":"","label":"Ano de ingresso na universidade de origem *","name":"inscricaoUniversidadeDeOrigem","outlined":"","validate-on-blur":""},model:{value:(_vm.inscricaoTEE.anoIngressoInstituicaoOrigem),callback:function ($$v) {_vm.$set(_vm.inscricaoTEE, "anoIngressoInstituicaoOrigem", $$v)},expression:"inscricaoTEE.anoIngressoInstituicaoOrigem"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],key:_vm.inscricaoTEE.anoIngressoInstituicaoOrigem,attrs:{"id":"numeroEnem","rules":[
          _vm.campoObrigatorioRule,
          _vm.tamanhoRule(12),
          _vm.processo.possuiPrefixoNumeroEnem
            ? _vm.prefixoRule(_vm.processo.listaPrefixoNumeroEnem)
            : true,
          _vm.processo.possuiPrefixoNumeroEnem
            ? _vm.validarNumeroEnemBaseadoNoAnoOrigem(_vm.inscricaoTEE.anoIngressoInstituicaoOrigem)
            : true
        ],"dense":"","label":"Número de inscrição no ENEM","name":"inscricaoNumeroEnem","maxLength":"12","outlined":"","validate-on-blur":""},model:{value:(_vm.inscricaoTEE.numeroEnem),callback:function ($$v) {_vm.$set(_vm.inscricaoTEE, "numeroEnem", $$v)},expression:"inscricaoTEE.numeroEnem"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"item-text":function (item) { return (item.nome + (item.sigla ? ' - ' + item.sigla : '')); },"items":_vm.instituicoesEnsino,"rules":[_vm.campoObrigatorioRule(_vm.inscricaoTEE.instituicaoEnsino.oid)],"dense":"","label":"Instituição de Ensino de graduação que está cursando","outlined":"","return-object":"","validate-on-blur":""},model:{value:(_vm.inscricaoTEE.instituicaoEnsino),callback:function ($$v) {_vm.$set(_vm.inscricaoTEE, "instituicaoEnsino", $$v)},expression:"inscricaoTEE.instituicaoEnsino"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }