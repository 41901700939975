<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
      </v-col>
      <v-col cols="6">
        <v-select
            v-if="processo.possuiUniversidadeOrigem"
            v-model="inscricaoTEE.anoIngressoInstituicaoOrigem"
            :items="processo.listaAnoIngressoUniversidadeOrigem"
            :rules="[campoObrigatorioRule]"
            dense
            label="Ano de ingresso na universidade de origem *"
            outlined
            validate-on-blur
        />
        <v-text-field
            v-else
            v-model="inscricaoTEE.anoIngressoInstituicaoOrigem"
            v-mask="'####'"
            :rules="[campoObrigatorioRule, tamanhoRule(4)]"
            dense
            label="Ano de ingresso na universidade de origem *"
            name="inscricaoUniversidadeDeOrigem"
            outlined
            validate-on-blur
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
            id="numeroEnem"
            :key="inscricaoTEE.anoIngressoInstituicaoOrigem"
            v-model="inscricaoTEE.numeroEnem"
            v-mask="'############'"
            :rules="[
            campoObrigatorioRule,
            tamanhoRule(12),
            processo.possuiPrefixoNumeroEnem
              ? prefixoRule(processo.listaPrefixoNumeroEnem)
              : true,
            processo.possuiPrefixoNumeroEnem
              ? validarNumeroEnemBaseadoNoAnoOrigem(inscricaoTEE.anoIngressoInstituicaoOrigem)
              : true
          ]"
            dense
            label="Número de inscrição no ENEM"
            name="inscricaoNumeroEnem"
            maxLength="12"
            outlined
            validate-on-blur
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
            v-model="inscricaoTEE.instituicaoEnsino"
            :item-text="
            item => (item.nome + (item.sigla ? ' - ' + item.sigla : ''))
          "
            :items="instituicoesEnsino"
            :rules="[campoObrigatorioRule(inscricaoTEE.instituicaoEnsino.oid)]"
            dense
            label="Instituição de Ensino de graduação que está cursando"
            outlined
            return-object
            validate-on-blur
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import instituicaoEnsinoService from "@/services/instituicaoEnsino.service";

export default {
  name: "InscricaoFormTEE",

  components: {
    EscolhaOpcaoSelect
  },

  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },

  data() {
    return {
      instituicoesEnsino: [],
      inscricaoTEE: {
        inscricao: null,
        numeroEnem: null,
        anoIngressoInstituicaoOrigem: null,
        instituicaoEnsino: {}
      }
    };
  },

  async created() {
    await instituicaoEnsinoService
        .recuperarInstituicoesEnsino()
        .then(response => {
          this.instituicoesEnsino = response.data;
        })
        .catch(() => {
        });
  },

  watch: {
    inscricaoTEE: {
      handler(){
        this.inscricaoTEE.inscricao = this.inscricao;
        this.$emit("input", this.inscricaoTEE);
      },
      deep: true
    }
  },

  methods: {
    ...rules
  }
};
</script>
